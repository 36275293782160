@media only screen and (min-width: 480px) {
    .pricing-title {
        font-family: Arvo;
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        padding: 1em;
        color: #dcc77a;
    }
    
    
    .pricing {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Arvo;
    }
    
    .pricing-about {
        margin: 2em 20em;
    }
    
    .pricing-cakes {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        margin: 2em 0;
    }
    
    .cakes {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    h3 {
        font-family: Arvo;
        font-weight: 400;
        font-style: normal;
        padding: 1em;
        color: #dcc77a;
        text-align: center;
    }

    p {
        text-align: center;
    }
        
}

@media only screen and (max-width: 480px) {
    .pricing-title {
        font-family: Arvo;
        font-weight: 400;
        font-style: normal;
        font-size: 30px;
        padding: 1em;
        color: #dcc77a;
    }
    
    .pricing {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-family: Arvo;
    }
    
    .pricing-about {
        margin: 2em;
    }
    
    .pricing-cakes {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    h3 {
        font-family: Arvo;
        font-weight: 400;
        font-style: normal;
        padding: 1em;
        color: #dcc77a;
        text-align: center;
    }
}
