@media only screen and (min-width: 480px) {
    .about {
        margin: 0 20em;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .header {
        margin: 0 20em;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        font-family: Arvo;
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        padding: 1em;
        color: #dcc77a;
    }
    .description {
        font-family: Arvo;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 5rem;
    }
    .navigation {
        margin: 2em;
    }
    .logo {
        height: 137px;
        width: 210px;
    }
    .nelli {
        max-width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 480px) {
    .about {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2em;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .description {
        font-family: Arvo;
        display: flex;
        flex-direction: column;
    }
    .logo {
        height: 68.5px;
        width: 105px;
    }
    .title {
        font-family: Arvo;
        font-weight: 400;
        font-style: normal;
        font-size: 30px;
        padding: 1em;
        color: #dcc77a;
        margin: 1em 0;
    }
    .nelli {
        max-width: 100%;
        height: auto;
        padding-bottom: 3em;
    }
}

.navigation {
    background-color: #0c0c0c!important;
}

.navigation a {
    color: #dcc77a!important;
    font-family: Arvo;
    font-size: small;
}