@media only screen and (min-width: 480px) {
    .ordering {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ordering-image {
        height: 500px;
        width: 375px;
        padding: 2em 0;
    }
    .ordering-content {
        display: flex;
        padding: 0 5em;
    }
    .ordering-text {
        padding: 2em;
        font-family: Arvo;
        text-align: center;
    }
}

@media only screen and (max-width: 480px) {
    .ordering {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ordering-image {
        height: 500px;
        width: 375px;
        padding: 2em 0;
    }
    .ordering-content {
        display: flex;
    }
    .ordering-text {
        padding: 2em;
        font-family: Arvo;
        text-align: center;
    }
}